/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'

const PostSearch = types
  .model({
    query: types.optional(types.model({
      categories: types.optional(types.array(types.string), []),
      search: types.optional(types.string, '')
    }), {}),
    limit: types.optional(types.integer, 21),
    page: types.optional(types.integer, -1),
    hasMore: types.optional(types.boolean, true),
    loading: types.optional(types.boolean, false)
  })

export default PostSearch
