/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'
import Genre from './genre'
// import Artist from './artist'
import Author from './author'

const Post = types
  .model({
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    datetime: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    image_large: types.maybeNull(types.string),
    short_description: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    sound_cloud: types.maybeNull(types.string),
    links: types.optional(types.model({ other: types.array(types.string) }), { other: [] }),
    tags: types.optional(types.array(types.string), []),
    categories: types.optional(types.array(Genre), []),
    author: types.maybeNull(Author)
    // places: types.optional(types.late(() => Place), {}, [null, undefined]),
    // artists: types.optional(types.late(() => Artist), {}, [null, undefined])
  })

export default Post
