import getConfig from 'next/config'
import fetch from 'isomorphic-unfetch'

import { i18n } from '../i18n'
import { fetchList, generateQueryString } from './utils'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export const fetchPartnerRoutes = (page, filters, limit, language, userToken) => (
  fetchList(page, filters, 'partner_routes', limit, language, userToken)
)

export const getEntityByID = async (entityID, entity, language = i18n.language, token, query = null) => {
  const userToken = token
  let entityUrl = `/api/v1/${entity}/${entityID}/`
  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    entityUrl = `${serverRuntimeConfig.serverAddress}${entityUrl}`
  }

  if (query) entityUrl += `?${generateQueryString(query)}`

  try {
    const res = await fetch(entityUrl, {
      headers: {
        Authorization: userToken ? `JWT ${userToken}` : undefined,
        Accept: 'application/json',
        'Accept-Language': language,
        Secret: publicRuntimeConfig.secret
      }
    })
    return await res.json()
  } catch (error) {
    console.error(error)
  }
  return null
}

export const favoriteRoute = async (routeID, entity, language = i18n.language, userToken, unfavorite) => {
  let favoriteRouteUrl = `/api/v1/${entity}/${routeID}/${
    unfavorite ? 'remove_from_favorites' : 'add_to_favorites'}/`
  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    favoriteRouteUrl = `${serverRuntimeConfig.serverAddress}${favoriteRouteUrl}`
  }

  try {
    if (userToken) {
      const res = await fetch(favoriteRouteUrl, {
        headers: {
          Authorization: `JWT ${userToken}`,
          Accept: 'application/json',
          'Accept-Language': language,
          Secret: publicRuntimeConfig.secret
        },
        method: 'POST'
      })
      return await res.json()
    }
  } catch (error) {
    console.error(error)
  }
  return null
}
