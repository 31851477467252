/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'

const Author = types
  .model({
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    avatar: types.maybeNull(types.string),
    slug: types.maybeNull(types.string)
  })

export default Author
