/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'

const Modal = types
  .model({
    isOpen: types.boolean,
    message: types.optional(types.string, ''),
    email: types.maybeNull(types.string)
  })
  .actions(self => ({
    setEmail(str) {
      self.email = str
      if (!self.isOpen) self.isOpen = true
    },
    open(message) {
      if (self.isOpen) return
      if (message) self.message = message
      self.isOpen = true
    },
    close() {
      if (!self.isOpen) return
      self.isOpen = false
      self.email = null
      self.message = ''
    }
  }))

export default Modal
