/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'
import Performance from './performance'
import Genre from './genre'

const Artist = types
  .model({
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    header_image: types.maybeNull(types.string),
    header_image_small: types.maybeNull(types.string),
    photo: types.maybeNull(types.string),
    headliner: types.optional(types.boolean, false),
    description: types.maybeNull(types.string),
    long_description: types.maybeNull(types.string),
    vk_playlist_code: types.maybeNull(types.string),
    genres: types.optional(types.array(Genre), []),
    links: types.optional(types.array(types.string), []),
    youtube_links: types.optional(types.array(types.string), [], [null, undefined]),
    tags: types.optional(types.array(types.string), []),
    performances: types.optional(types.array(types.late(() => Performance)), []),
    favorited: types.optional(types.boolean, false),
    favorited_count: types.optional(types.integer, 0),
    partner: types.maybeNull(types.frozen())
  })

export default Artist
