/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'

const Genre = types
  .model({
    label: types.optional(types.string, ''),
    name: types.optional(types.string, '')
  })

export default Genre
