/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'
import Performance from './performance'

const Place = types
  .model({
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    description: types.maybeNull(types.string),
    links: types.optional(types.array(types.string), []),
    address: types.optional(types.string, ''),
    phone: types.maybeNull(types.string),
    header_image: types.maybeNull(types.string),
    photo: types.maybeNull(types.string),
    tags: types.optional(types.array(types.string), []),
    coordinates: types.array(types.frozen()),
    performances: types.optional(types.array(types.late(() => Performance)), []),
    experts: types.array(types.frozen()),
    partner: types.maybeNull(types.frozen())
  })

export default Place
