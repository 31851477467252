/* eslint-disable no-param-reassign,import/no-cycle */
import { types } from 'mobx-state-tree'
import Place from './place'
import Artist from './artist'

const Performance = types
  .model({
    id: types.optional(types.union(types.string, types.number), ''),
    favorited: types.maybeNull(types.boolean),
    selected: types.maybeNull(types.boolean),
    name: types.maybeNull(types.string),
    slug: types.maybeNull(types.string),
    time: types.maybeNull(types.string),
    start_datetime: types.maybeNull(types.string),
    end_datetime: types.maybeNull(types.string),
    place: types.optional(types.late(() => Place), {}, [null, undefined]),
    artist: types.optional(types.late(() => Artist), {}, [null, undefined])
  })

export default Performance
